import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  CardList,
  CardListItem,
  CircleButton,
  Form,
  LysaFormRef,
  NewIcon,
  SNACKBAR_TYPES,
  SelectableCard,
  Snackbar,
  Typography,
} from "@lysaab/ui-2";
import { useHistory } from "react-router";
import {
  DEFAULT_PAYOUT_AGE,
  DEFAULT_WITHDRAWAL_MONTHS,
  TransferContextState,
  useTransfer,
} from "../TransferContext";
import { useFees } from "../hooks/useFees";
import {
  GetPensionSuitabilityAssessmentRequest,
  GetSuitabilityAssessmentResponse,
  GlidepathDataPoint,
  SustainabilityImportance,
  dataInvestments,
  getPensionAccountQuestions,
} from "../../../../../data/dataInvestments";
import { LysaCountry } from "@lysaab/shared";
import { getUserAge } from "../utils/userAge";
import { useIntl } from "react-intl";
import { useUser } from "../../../../../context/UserContext";
import { Modal } from "../../../../../components/modal/Modal";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { GlidepathGraph } from "../components/glidepathGraph/GlidepathGraph";
import { useLanguage } from "../../../../../context/LocalizationContext";
import { FormattedPercentage } from "../../../../../components/formattedPercentage/FormattedPercentage";

import "./Advice.scss";

interface Props {
  next: () => void;
  toFees: () => void;
  toHoldings: () => void;
  toPensionSettings: () => void;
}

interface GraphData {
  glidePath: GlidepathDataPoint[];
  age: number;
  withdrawalAge: number;
}

export const Advice: FunctionComponent<Props> = ({
  next,
  toFees,
  toHoldings,
  toPensionSettings,
}) => {
  const intl = useIntl();
  const [transfer] = useTransfer();
  const formRef = useRef<LysaFormRef>();
  const user = useUser();
  const age = getUserAge(user.tin);
  const [glidepath, setGlidepath] = useState<GraphData>({
    age: age ?? 0,
    withdrawalAge: transfer.withdrawalAge,
    glidePath: [],
  });
  const history = useHistory();
  const language = useLanguage();
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();
  const fees = useFees();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  useEffect(() => {
    if (typeof transfer?.sustainability !== "undefined") {
      const data: Partial<GetPensionSuitabilityAssessmentRequest> = {
        language: language,
        age: age,
        ...getPensionAccountQuestions(transfer),
      };

      dataInvestments.getNewPensionAccountSuitability(data).then(setAdvise);
    }
  }, [transfer, language, age]);

  useEffect(() => {
    if (
      !age ||
      user.legalEntityType !== LegalEntityType.PERSON ||
      user.country !== LysaCountry.SWEDEN
    ) {
      return;
    }

    dataInvestments
      .getPensionGlidepath(
        age,
        transfer.withdrawalAge,
        transfer.withdrawalMonths,
        transfer.takenRiskDeviation
      )
      .then((response) => {
        /**
         * For smoother lines we only need first, rebalance start, rebalance end and last.
         */
        const firstTakenRisk = response.glidePath[0].takenRisk;
        const indexForRebalanceStart = response.glidePath.reduceRight(
          (foundIndex, element, index) => {
            if (foundIndex === -1 && element.takenRisk === firstTakenRisk) {
              return index;
            }
            return foundIndex;
          },
          -1
        );

        const first = response.glidePath[0];
        const rebalanceStart =
          response.glidePath[Math.max(indexForRebalanceStart, 0)];
        const rebalanceEnd = response.glidePath[response.glidePath.length - 2];
        const last = response.glidePath[response.glidePath.length - 1];

        const smoothGlidepath = [first, rebalanceStart, rebalanceEnd, last];

        setGlidepath({
          glidePath: smoothGlidepath,
          age,
          withdrawalAge: transfer.withdrawalAge,
        });
      });
  }, [
    age,
    transfer.withdrawalAge,
    transfer.withdrawalMonths,
    transfer.takenRiskDeviation,
    user.country,
    user.legalEntityType,
    user.tin,
  ]);

  if (!age) {
    return null;
  }

  const stocksStart = Math.min(100 + transfer.takenRiskDeviation, 100);
  const stocksEnd = 20 + transfer.takenRiskDeviation;

  const rebalanceDefault = 10;
  let rebalanceStart = rebalanceDefault;

  const userHasMadeChange =
    transfer.withdrawalAge !== DEFAULT_PAYOUT_AGE ||
    transfer.withdrawalMonths !== DEFAULT_WITHDRAWAL_MONTHS ||
    transfer.takenRiskDeviation !== 0;

  // Wait for glidepath to initialised in state
  if (glidepath.glidePath.length > 0) {
    const rebalanceStartIndex =
      glidepath.glidePath.findIndex((datapoint) => datapoint.takenRisk < 100) -
      1;
    // Check if a valid index was found
    if (rebalanceStartIndex !== -1) {
      const rebalanceStartAge =
        glidepath.glidePath[rebalanceStartIndex].age + 1;
      rebalanceStart = Math.abs(
        Math.min(rebalanceDefault, transfer.withdrawalAge - rebalanceStartAge)
      );
    }
  }

  return (
    <article className="transfer-pension-story-advice">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <div className="header-row">
          <Typography type="h3" className="heading">
            <TranslatedText id="sweden.transfer-pension.advice.header" />
          </Typography>
          <Button
            className="edit"
            size="small"
            variant="secondary"
            type="button"
            label={intl.formatMessage({
              id: "sweden.transfer-pension.advice.edit",
            })}
            onClick={toPensionSettings}
          />
        </div>
        <InvestmentBasis
          age={age}
          stocksStart={stocksStart}
          rebalanceStart={rebalanceStart}
          stocksEnd={stocksEnd}
          userHasMadeChange={userHasMadeChange}
          advise={advise}
        />
        <GlidepathGraph
          glidepath={glidepath.glidePath}
          age={glidepath.age}
          withdrawalAge={glidepath.withdrawalAge}
        />
        <Summary transfer={transfer} />
        <InvestmentTypeSection sustainability={transfer.sustainability} />
        <hr className="divider" />
        <SelectableCard
          type="button"
          className="selectable-card"
          onClick={toHoldings}
        >
          <header className="header">
            <section className="start">
              <Typography type="label" className="label">
                <TranslatedText id={"sweden.transfer-pension.holdings.label"} />
              </Typography>
            </section>
            <section className="end">
              <NewIcon.ChevronRight />
            </section>
          </header>
        </SelectableCard>
        <SelectableCard
          type="button"
          className="selectable-card"
          onClick={toFees}
        >
          <header className="header">
            <section className="start">
              <Typography type="label" className="label">
                <TranslatedText id={"sweden.transfer-pension.fees.label"} />
              </Typography>
            </section>
            <section className="end">
              {fees?.cost.total && (
                <FormattedPercentage value={fees?.cost.total} decimals={3} />
              )}
              <NewIcon.ChevronRight />
            </section>
          </header>
        </SelectableCard>
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <div style={{ textAlign: "left" }}>
            <TranslatedText id="sweden.transfer-pension.advice.snackbar" />
          </div>
        </Snackbar>
        <section className="action-buttons">
          <Button
            block
            type="button"
            label={intl.formatMessage({
              id: "sweden.transfer-pension.advice.next",
            })}
            onClick={next}
          />
        </section>
      </Form>
    </article>
  );
};

/**
 *
 * Components
 *
 * TODO: Move to own modules
 *
 */

function InvestmentBasis({
  age,
  stocksStart,
  rebalanceStart,
  stocksEnd,
  userHasMadeChange,
  advise,
}: {
  age: number;
  stocksStart: number;
  rebalanceStart: number;
  stocksEnd: number;
  userHasMadeChange: boolean;
  advise?: GetSuitabilityAssessmentResponse;
}) {
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  if (age >= DEFAULT_PAYOUT_AGE || !advise) {
    return null;
  }

  return (
    <>
      <Typography type="body">
        {/** This can for sure be made shorter wit string interpolation but translations script can't handle it. Will delete your translation. */}
        {userHasMadeChange ? (
          <TranslatedText
            id="sweden.transfer-pension.advice.investment-basis.adjusted"
            values={{
              stocksStart: () => {
                return <span>{stocksStart}</span>;
              },
              rebalanceStart: () => {
                return <span>{rebalanceStart}</span>;
              },
              stocksEnd: () => {
                return <span>{stocksEnd}</span>;
              },
              investmentBasis: (text) => {
                return (
                  <Button
                    inline
                    variant="outlined"
                    size="small"
                    label={text}
                    className="show-investment-basis"
                    onClick={handleModalOpen}
                  />
                );
              },
            }}
          />
        ) : (
          <TranslatedText
            id="sweden.transfer-pension.advice.investment-basis.default"
            values={{
              stocksStart: () => {
                return <span>{stocksStart}</span>;
              },
              rebalanceStart: () => {
                return <span>{rebalanceStart}</span>;
              },
              stocksEnd: () => {
                return <span>{stocksEnd}</span>;
              },
              investmentBasis: (text) => {
                return (
                  <Button
                    inline
                    variant="outlined"
                    size="small"
                    label={text}
                    className="show-investment-basis"
                    onClick={handleModalOpen}
                  />
                );
              },
            }}
          />
        )}
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.advice.change.instructions" />
      </Typography>
      {showModal && (
        <InvestmentBasisModal onClose={handleModalClose} showModal={showModal}>
          <Typography type="body">
            <span>{advise.declaration}</span>
          </Typography>
        </InvestmentBasisModal>
      )}
    </>
  );
}

function InvestmentBasisModal({
  onClose,
  showModal,
  children,
}: {
  onClose: () => void;
  showModal: boolean;
  children?: ReactNode;
}) {
  const intl = useIntl();

  return (
    <Modal
      header={intl.formatMessage({
        id: "sweden.transfer-pension.advice.investment-basis.modal.header",
      })}
      showModal={!!showModal}
      onClose={onClose}
      closeOnOverlayClick
    >
      {children}
    </Modal>
  );
}

function Summary({ transfer }: { transfer: TransferContextState }) {
  const intl = useIntl();
  const [showRepaymentInformation, setShowRepaymentInformation] =
    useState(false);

  return (
    <CardList className="summary">
      <CardListItem>
        <dl>
          <dt>
            {intl.formatMessage({
              id: "sweden.transfer-pension.advice.withdrawalAge.header",
            })}
          </dt>
          <dd>
            {`${transfer.withdrawalAge} ${intl.formatMessage({
              id: "sweden.transfer-pension.advice.withdrawalAge.unit",
            })}`}
          </dd>
        </dl>
      </CardListItem>
      <CardListItem>
        <dl>
          <dt>
            {intl.formatMessage({
              id: "sweden.transfer-pension.advice.withdrawalPeriod.header",
            })}
          </dt>
          <dd>
            {`${transfer.withdrawalMonths / 12} ${intl.formatMessage({
              id: "sweden.transfer-pension.advice.withdrawalPeriod.unit",
            })}`}
          </dd>
        </dl>
      </CardListItem>
      <CardListItem>
        <dl>
          <dt>
            {intl.formatMessage({
              id: "sweden.transfer-pension.advice.repayment.header",
            })}
            <CircleButton
              className="repayment-information-button"
              icon="InformationOutline"
              onClick={() => {
                setShowRepaymentInformation(true);
              }}
            />
          </dt>
          <dd>
            {`${
              transfer.repayment
                ? intl.formatMessage({
                    id: "sweden.transfer-pension.advice.repayment.yes",
                  })
                : intl.formatMessage({
                    id: "sweden.transfer-pension.advice.repayment.no",
                  })
            }`}
          </dd>
        </dl>
        {showRepaymentInformation && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.advice.repayment.modal.header",
            })}
            showModal={!!showRepaymentInformation}
            onClose={() => setShowRepaymentInformation(false)}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.advice.repayment.information.one" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.advice.repayment.information.two" />
            </Typography>
          </Modal>
        )}
      </CardListItem>
    </CardList>
  );
}

function InvestmentTypeSection({
  sustainability,
}: {
  sustainability?: SustainabilityImportance;
}) {
  if (!sustainability) {
    return null;
  }

  return (
    <section className="investment-type">
      <dl>
        <InvestmentTypeLabel />
        <InvestmentTypeValue sustainability={sustainability} />
      </dl>
    </section>
  );
}

function InvestmentTypeLabel() {
  const intl = useIntl();
  return (
    <dt>
      {intl.formatMessage({
        id: "sweden.transfer-pension.advice.investment-type",
      })}
    </dt>
  );
}

function InvestmentTypeValue({
  sustainability,
}: {
  sustainability: SustainabilityImportance;
}) {
  const intl = useIntl();

  const getMessage = () => {
    return sustainability === SustainabilityImportance.NOT_IMPORTANT
      ? intl.formatMessage({
          id: "sweden.transfer-pension.advice.advise.sustainability.BROAD",
        })
      : intl.formatMessage({
          id: "sweden.transfer-pension.advice.advise.sustainability.SUSTAINABLE",
        });
  };

  const getIcon = () => {
    return sustainability === SustainabilityImportance.NOT_IMPORTANT ? (
      <NewIcon.Global size={16} />
    ) : (
      <NewIcon.Sustainable size={16} />
    );
  };

  return (
    <dd>
      {getMessage()}
      {getIcon()}
    </dd>
  );
}
