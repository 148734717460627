import { FunctionComponent, useEffect, useState } from "react";

import { Alternative, Button, Select, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./WithdrawalPeriod.scss";
import {
  dataInvestments,
  GlidepathDataPoint,
} from "../../../../../data/dataInvestments";
import { defineMessages, useIntl } from "react-intl";
import { DEFAULT_WITHDRAWAL_MONTHS, useTransfer } from "../TransferContext";
import { useHistory } from "react-router";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { LysaCountry } from "@lysaab/shared";
import { GlidepathGraph } from "../components/glidepathGraph/GlidepathGraph";

interface GraphData {
  glidePath: GlidepathDataPoint[];
  age: number;
  withdrawalAge: number;
}

const messages = defineMessages({
  customPayoutTimePreference: {
    id: "sweden.transfer-pension.advice.payout-time.alternative",
  },
  customPayoutTimeDefaultPreference: {
    id: "sweden.transfer-pension.advice.payout-time.default-alternative",
  },
});

const PAYOUT_INTERVAL_START = 5;
const PAYOUT_INTERVAL_END = 20;

export const WithdrawalPeriod: FunctionComponent = () => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();
  const user = useUser();
  const age = getUserAge(user.tin);
  const [glidepath, setGlidepath] = useState<GraphData>({
    age: age ?? 0,
    withdrawalAge: transfer.withdrawalAge,
    glidePath: [],
  });

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  useEffect(() => {
    if (
      !age ||
      user.legalEntityType !== LegalEntityType.PERSON ||
      user.country !== LysaCountry.SWEDEN
    ) {
      return;
    }

    dataInvestments
      .getPensionGlidepath(
        age,
        transfer.withdrawalAge,
        transfer.withdrawalMonths,
        transfer.takenRiskDeviation
      )
      .then((response) => {
        /**
         * For smoother lines we only need first, rebalance start, rebalance end and last.
         */
        const firstTakenRisk = response.glidePath[0].takenRisk;
        const indexForRebalanceStart = response.glidePath.reduceRight(
          (foundIndex, element, index) => {
            if (foundIndex === -1 && element.takenRisk === firstTakenRisk) {
              return index;
            }
            return foundIndex;
          },
          -1
        );

        const first = response.glidePath[0];
        const rebalanceStart =
          response.glidePath[Math.max(indexForRebalanceStart, 0)];
        const rebalanceEnd = response.glidePath[response.glidePath.length - 2];
        const last = response.glidePath[response.glidePath.length - 1];

        const smoothGlidepath = [first, rebalanceStart, rebalanceEnd, last];

        setGlidepath({
          glidePath: smoothGlidepath,
          age,
          withdrawalAge: transfer.withdrawalAge,
        });
      });
  }, [
    age,
    transfer.withdrawalAge,
    transfer.withdrawalMonths,
    transfer.takenRiskDeviation,
    user.country,
    user.legalEntityType,
    user.tin,
  ]);

  if (!age) {
    return null;
  }

  const timeAlternatives = generateYearArray(
    PAYOUT_INTERVAL_START,
    PAYOUT_INTERVAL_END
  ).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(
        year === DEFAULT_WITHDRAWAL_MONTHS / 12
          ? messages.customPayoutTimeDefaultPreference
          : messages.customPayoutTimePreference,
        {
          years: year,
        }
      ),
      value: year,
    })
  );

  return (
    <article className="transfer-pension-story-withdrawal-period">
      <Typography type="h3" className="header">
        <TranslatedText id="sweden.transfer-pension.withdrawal-period.header" />
      </Typography>
      <section className="glide-path">
        <GlidepathGraph
          glidepath={glidepath.glidePath}
          age={glidepath.age}
          withdrawalAge={glidepath.withdrawalAge}
        />
      </section>
      <Typography type="h3" className="heading">
        <TranslatedText id="sweden.transfer-pension.withdrawal-period.heading" />
      </Typography>
      <Select
        alternatives={timeAlternatives}
        label=" "
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.advice.payout-period.select.placeholder",
        })}
        value={timeAlternatives.find(
          (alternative) => alternative.value === transfer.withdrawalMonths / 12
        )}
        onChange={(newValue) =>
          setTransfer({ withdrawalMonths: newValue.value * 12 })
        }
      />
      <Button
        className="save-button"
        block
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.withdrawal-period.save",
        })}
        onClick={history.goBack}
      />
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}
